<template>
    <div>
        <ViewTitle
            :title="`業績報表 | 單位業績明細表`"
            class="viewtitle-container"
        >
            <template v-slot:buttons>
                <div class="flex mode-button">
                    <Button
                        buttonWord="傳統版"
                        buttonStyle="grey"
                        :class="mode === '1' ? 'active' : ''"
                        @click.prevent="changeMode('1')"
                    />
                    <Button
                        buttonWord="網頁版"
                        buttonStyle="grey"
                        :class="mode === '2' ? 'active' : ''"
                        @click.prevent="changeMode('2')"
                    />
                </div>
            </template>
        </ViewTitle>
        <TagMenu
            :options="[
                { label: '傳統版報表', value: '1' },
                { label: '網頁版報表', value: '2' }
            ]"
            :mode="mode"
            @change-mode="changeMode"
        />
        <DetailFilter
            :mode="mode"
            :listData="listData"
            :stylerList="stylerList"
            :isLoading="isLoadingTable"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <div v-if="isLoadingTable" class="table-remark loading"></div>
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isHasListData="listData.length > 0"
            :isLoading="isLoadingTable"
        />
        <keep-alive>
            <DetailTable1
                v-if="mode === '1'"
                :bgName="bgName"
                :listData="listData"
                :filterLog="filterLog"
                :stylerList="stylerList"
                :licenseList="licenseList"
                :performanceList="performanceList"
                :isLoading="isLoadingTable"
                :infos="searchConditionList"
                :columnsMode="'1'"
            />
            <DetailTable2
                v-else
                :bgName="bgName"
                :listData="listData"
                :filterLog="filterLog"
                :stylerList="stylerList"
                :licenseList="licenseList"
                :performanceList="performanceList"
                :isLoading="isLoadingTable"
                :infos="searchConditionList"
                :columnsMode="'2'"
            />
        </keep-alive>
        <MobileAlertModal v-model="isShowMobileAlert" />
        <WaitingModal
            v-model="isShowWaitingModal"
            :isLoading="isLoadingTable"
            :isSuccess="!isLoadingTable"
        />
    </div>
</template>

<script>
// import _ from 'lodash'
import TagMenu from '@/components/TagMenu.vue'
import Button from '@/components/Button.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import DetailFilter from '@/containers/report/DetailFilter.vue'
import DetailTable1 from '@/containers/report/DetailTable1.vue'
import DetailTable2 from '@/containers/report/DetailTable2.vue'
import MobileAlertModal from '@/containers/report/MobileAlertModal.vue'
import WaitingModal from '@/containers/performance/WaitingModal.vue'
import { getReportListDetailAPI } from '@/assets/javascripts/api'

export default {
    name: 'ReportDetail',
    components: {
        TagMenu,
        Button,
        ViewTitle,
        DetailFilter,
        DetailTable1,
        DetailTable2,
        MobileAlertModal,
        WaitingModal,
        SearchCondition
    },
    methods: {
        changeMode: function (goto) {
            const current = this.$route.params.mode
            const mode = goto || (current === '1' ? '2' : '1')
            if (mode !== current) {
                this.$setGaEvent(`clickGoToMode${mode}`, 'click-Tag')
                this.$router.push({
                    path: `/report/detail/${mode}`
                })
            }
        },
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            this.isShowWaitingModal = true
            this.isLoadingTable = true

            /* Get Table Data */
            try {
                const response = await this.$getResponse(
                    this.filterPayload,
                    {
                        apiAlias: 'list',
                        apiName: '此報表'
                    },
                    getReportListDetailAPI
                )
                const data = response.data[0]
                this.dataProcess(data)
            } catch (error) {
                this.listData = []
            } finally {
                this.isLoadingTable = false
            }
        },
        dataProcess: function (data) {
            const rootStyle = {
                root: {
                    skipRowAbove: 1
                }
            }
            const range = data?.header?.reportInfo?.performance?.currentSubmit
            this.listData = data?.member || []
            this.performanceList = data?.performance || {}
            this.licenseList = data?.licence || {} // BE: licence FE: license
            this.stylerList = Object.assign(rootStyle, data?.styler || {})
            this.bgName = data?.header?.name || ''
            this.startDate = range?.startDisplay.substring(0, 10) || ''
            this.endDate =
                data?.header?.calculationDateDisplay?.substring(0, 10) || ''
            this.endDate = this.$formatDate(
                this.$getDate(0, 0, 0, this.endDate)
            )
        }
    },
    computed: {
        mode: function () {
            return this.$route.params.mode
        },
        searchConditionList: function () {
            return [
                {
                    title: '受理日區間',
                    value: `${this.startDate} 至 ${this.endDate}`
                }
            ]
        },
        listenShowModal: function () {
            return {
                mobileAlert: this.isShowMobileAlert,
                waiting: this.isShowWaitingModal
            }
        }
    },
    watch: {
        listenShowModal: {
            handler() {
                const listen = this.listenShowModal
                const body = document.querySelector('body')
                if (!listen.waiting && listen.mobileAlert) {
                    setTimeout(() => {
                        this.$addAllClass(body, 'not-scroll')
                    }, 500)
                }
            },
            deep: true
        }
    },
    data() {
        return {
            isShowMobileAlert: false,
            isShowWaitingModal: false,
            isLoadingTable: false,
            tableShowData: [],
            listData: [],
            performanceList: {},
            licenseList: {},
            stylerList: {},
            bgName: '',
            startDate: '',
            endDate: '',
            pathName: '',
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                endDate: '',
                relationMode: ''
            },
            filterLog: {}
        }
    },
    async created() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>

<style lang="scss" scoped>
.mode-button {
    margin-bottom: -35px;
    .button {
        background-color: $forth-white;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-right: 0px;
        &.active {
            position: relative;
            background-color: $primary-white;
            border-color: $primary-white;
            box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
            cursor: auto;
            &:last-of-type {
                box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.3);
            }
        }
        &:not(.active) {
            :deep(.button-word) {
                opacity: 0.5;
            }
            &:hover {
                :deep(.button-word) {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and(max-width:576px) {
        display: none;
    }
}

:deep(.scroll-wrapper) {
    display: none;
    @media screen and(max-width:576px) {
        display: block;
    }
}

.card {
    display: none;
    @media screen and (max-width: 576px) {
        display: block;
    }
}

.count-message {
    font-size: 15px;
    color: $secondary-grey;
    text-align: right;
    padding-top: 20px;
    padding-right: 20px;
}

.table-remark {
    min-height: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $secondary-grey;
    font-size: 14px;
    span {
        margin-right: 10px;
    }

    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
}
</style>
